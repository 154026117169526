import { Component, OnInit, Inject  } from '@angular/core';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';

@Component({
  selector: 'app-useroptions',
  templateUrl: './useroptions.component.html',
  styleUrls: ['./useroptions.component.css']
})
export class UseroptionsComponent implements OnInit {

  constructor(private configServer: ServicepoolService, public dialogRef: MatDialogRef<UseroptionsComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) { 
  }

  ngOnInit(): void {
  }


  annuler(){
  	this.dialogRef.close() ;
  }

}
