import { Component, OnInit, Inject } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-livraison',
  templateUrl: './livraison.component.html',
  styleUrls: ['./livraison.component.css']
})
export class LivraisonComponent implements OnInit {

	commands = [] ;

	animal = "gdg";

	constructor(public dialog: MatDialog) { }

	ngOnInit(): void {
		this.commands.push({"commande" : "shwrm3420", "dateLivr" : "15/04/2021 15:14", "origine" : "UCAD", "destination" : "Point E", "etat" : 0 , "referant": "77 - 33", "client":"771135383", "nomclient": "Abdoulaye Fall", "nomreferant": "Mouhamed Fall"}) ;
		this.commands.push({"commande" : "site45937", "dateLivr" : "15/04/2021 15:12", "origine" : "Almadies", "destination" : "Yoff", "etat" : 0 , "referant": "77 - 33", "client":"706776383", "nomclient": "Minerva Hooper", "nomreferant": "Mouhamed Fall" }) ;
		this.commands.push({"commande" : "site45938", "dateLivr" : "15/04/2021 15:00", "origine" : "Pikine", "destination" : "Yeumbeul", "etat" : 1 , "referant": "77 - 33", "client":"780699883", "nomclient": "Abdoulaye Fall", "nomreferant": "Mouhamed Fall" }) ;
		this.commands.push({"commande" : "whtsp849", "dateLivr" : "15/04/2021 14:30", "origine" : "Case bi", "destination" : "Parcelles Assainies U24", "etat" : 2 , "referant": "77 - 33", "client":"751235383", "nomclient": "Abdoulaye Fall", "nomreferant": "Mouhamed Fall" }) ;
		this.commands.push({"commande" : "fcbk8703", "dateLivr" : "15/04/2021 14:13", "origine" : "Kabirex", "destination" : "Bourguiba", "etat" : 0 , "referant": "77 - 33", "client":"778735383", "nomclient": "Abdoulaye Fall", "nomreferant": "Mouhamed Fall" }) ;
		this.commands.push({"commande" : "fcbk8703", "dateLivr" : "15/04/2021 13:45", "origine" : "Kabirex", "destination" : "Sacré-Coeur", "etat" : 1 , "referant": "77 - 33", "client":"760035383", "nomclient": "Abdoulaye Fall", "nomreferant": "Mouhamed Fall" }) ;
	}

  
	openDialog(cmd): void {
		console.log(cmd) ;
		const dialogRef = this.dialog.open(Deliverymodale, {
		  data: cmd
		});

		dialogRef.afterClosed().subscribe(result => {
		  console.log('The dialog was closed');
		  this.animal = result;
		});
	}

}


@Component({
  selector: 'deliverymodale',
  templateUrl: 'deliverymodale.html',
})
export class Deliverymodale {

  constructor(
    public dialogRef: MatDialogRef<Deliverymodale>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  	console.log(this.data.commande) ;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
