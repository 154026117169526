import { Component, OnInit, Inject, Input, Output, ViewChild, AfterViewInit} from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { DetailcommandeComponent } from '../detailcommande/detailcommande.component';
import {Observable} from 'rxjs';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';




@Component({
  selector: 'app-taux',
  templateUrl: './taux.component.html',
  styleUrls: ['./taux.component.css']
})
export class TauxComponent implements OnInit {

	allTaux : any ; /*[ 
				{code: "USD" , nom: "Dollar Americain", hasicon: 1, icon: "attach_money", achat: 620, vente: 670 }, 
				{code: "Eur" , nom: "Euro", "hasicon": 1, icon: "euro", achat: 650, vente: 680 },
				{code: "GBP" , nom: "Livre Britanique", hasicon: 1, icon: "currency_pound", achat: 730, vente: 780 },
				{code: "CNY" , nom: "YUAN Chinois", hasicon: 1, icon: "currency_yuan", achat: 90, vente: 130 },
				{code: "RUB" , nom: "Rouble Russe", hasicon: 1, icon: "currency_ruble", achat: 10, vente: 15 },
				{code: "JPY" , nom: "Yen Japonais", hasicon: 1, icon: "currency_yen", achat: 5, vente: 15 },
				{code: "INR" , nom: "Roupie Indienne", hasicon: 1, icon: "currency_rupee", achat: 7, vente: 13 },
			] ;*/

	displayedColumns: string[] = ['icon', 'Code', 'Nom',  'Achat', 'Vente'];
	dataSource  : any ;

	samacaabiyoonwii : any ;
	retourClientWS  : ReponseWebService ;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {

	}

    getAllCurrenciesRates(){

	    this.configServer.getAllCurrenciesRates(this.samacaabiyoonwii).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        this.allTaux = this.retourClientWS.data ; 

	        console.log(this.allTaux) ;

	        this.dataSource = new MatTableDataSource(this.allTaux);
	      }
	      else
	        console.log("Failed") ;
	    }) ;	    
	}




	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
		this.getAllCurrenciesRates() ;
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}



}
