import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/homecpnt/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/homecpnt/partners', title: 'Réseau',  icon: 'toc', class: '' },
    { path: '/homecpnt/compta', title: 'Finances',  icon:'library_books', class: '' },
    { path: '/homecpnt/documents', title: 'G.E.D / Archives',  icon: 'money', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {

    var ROUTES2 = [
      { path: '/homecpnt/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
      { path: '/homecpnt/compta', title: 'Reporting',  icon:'library_books', class: '' },
    ];

    var aL = sessionStorage.getItem("accessl") ;

    if(aL=="2"){
      this.menuItems = ROUTES2.filter(menuItem => menuItem);
    }else{
      this.menuItems = ROUTES.filter(menuItem => menuItem);      
    }

  }

  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
