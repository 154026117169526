import { Component, OnInit, Inject, Input, Output, ViewChild, AfterViewInit} from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { DetailcommandeComponent } from '../detailcommande/detailcommande.component';
import {Observable} from 'rxjs';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';



@Component({
  selector: 'app-soldes',
  templateUrl: './soldes.component.html',
  styleUrls: ['./soldes.component.css']
})
export class SoldesComponent implements OnInit {

	allSoldes : any ; /*[ 
				{code: "USD" , nom: "Dollar Americain", hasicon: 1, icon: "attach_money", solde: 620 }, 
				{code: "Eur" , nom: "Euro", "hasicon": 1, icon: "euro", solde: 350 },
				{code: "GBP" , nom: "Livre Britanique", hasicon: 1, icon: "currency_pound", solde: 700 },
				{code: "CNY" , nom: "YUAN Chinois", hasicon: 1, icon: "currency_yuan", solde: 500 },
				{code: "RUB" , nom: "Rouble Russe", hasicon: 1, icon: "currency_ruble", solde: 100 },
				{code: "JPY" , nom: "Yen Japonais", hasicon: 1, icon: "currency_yen", solde: 455 },
				{code: "INR" , nom: "Roupie Indienne", hasicon: 1, icon: "currency_rupee", solde: 700 },
			] ;
*/
	displayedColumns: string[] = ['icon', 'Monnaie', 'Solde'];
	dataSource  : any ;

	totalAchat = 0 ;
	totalVente = 0 ;
	solde  : any  ;

	intervalId : any ;

	samacaabiyoonwii = "" ;

	retourClientWS  : ReponseWebService ;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
	}

    getAllCurrenciesSoldes(){

	    this.configServer.getAllCurrenciesSoldes(this.samacaabiyoonwii).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;
	      console.log("THERE WE GO FOR SOLDES") ;
	      console.log(this.retourClientWS) ;
	      if(statutrec==1){
	        this.allSoldes = this.retourClientWS.data ; 
  	    	this.dataSource = new MatTableDataSource(this.allSoldes);		
	      }
	      else
	        console.log("Failed") ;
	    }) ;	    
	}


    getCaisseXOF(){

	    this.configServer.getCaisseXOF(this.samacaabiyoonwii).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;
	      console.log("THERE WE GO FOR SOLDES") ;
	      console.log(this.retourClientWS) ;
	      if(statutrec==1){
	      	console.log(this.retourClientWS.data.soldes_fermet) ;
	      	if(this.retourClientWS.data.soldes_fermet!=undefined )
		        this.solde = this.retourClientWS.data.soldes_fermet ; 
		    else
		    	this.solde = 0 ;
	      }
	      else
	        console.log("Failed") ;
	    }) ;	    
	}



	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
	     console.log("THERE WE GO FOR SOLDES") ;
	     this.getCaisseXOF(); 
	    this.getAllCurrenciesSoldes() ;
	}

/*	updateValues(){
		this.totalVente = Number(sessionStorage.getItem("totalVente")) ;
		this.totalAchat = Number(sessionStorage.getItem("totalAchat")) ;
		this.solde = this.solde + this.totalVente - this.totalAchat ;
	}
*/

	getMontantFormatee(montant){
		let resultd = Number(montant).toLocaleString('fr-FR');
		return resultd ;
	}


	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


}
