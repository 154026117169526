import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {ResponseContentType, RequestOptions } from '@angular/http';

import { Observable, throwError } from 'rxjs';

import * as sha1 from 'js-sha1';

import * as _ from 'lodash'


import { ReponseWebService } from '../datastruct/Meloyi.module';



const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};



@Injectable({
  providedIn: 'root'
})
export class ServicepoolService {

   baseUrl = "https://change.smkfinances.com/change/" ;


  constructor(private http: HttpClient, private http2: HttpClient) { }

  addNewConfig(idUser, config, nomassurance): Promise<ReponseWebService>{   
    return new Promise( (resolve, reject) => {

		let postData = {idUser: idUser, config:config, nomassurance:nomassurance} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://localhost/Galia/addconfig.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }



  assignerCourse(idcourse, idusertransport): Promise<any>{  
    return new Promise( (resolve, reject) => {
    	
		let postData = {course: idcourse, chauffeur:idusertransport} ;  

	    let backData  : ReponseWebService ;

	    this.remotelogger('http://144.91.121.43/dieulsima/public/index.php/api/admassignecourse', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  public insertToBd(info:string):Promise<any>{

    return new Promise( (resolve, reject) => {
		
		let postData = {infos: info} ;  

	    let backData  : ReponseWebService ;

        console.log(postData) ;

	    this.remotelogger('http://144.91.121.43/lis/payupload.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});

  }


  getAllPresences(deb, fin): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1, datedeb: deb, datefin: fin} ;  
	    let backData  : ReponseWebService ;

        console.log(postData) ;

	    this.remotelogger('http://144.91.121.43/lis/allpresences.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getAllRetards(deb, fin): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1, datedeb: deb, datefin: fin} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://144.91.121.43/lis/allretards.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getAllAbsences(deb, fin): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1, datedeb: deb, datefin: fin} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://144.91.121.43/lis/allabsences.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }




  getAllPresencesAggr(deb, fin): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1, datedeb: deb, datefin: fin} ;  
	    let backData  : ReponseWebService ;

        console.log(postData) ;

	    this.remotelogger('http://144.91.121.43/lis/allpresencesAggr.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getAllRetardsAggr(deb, fin): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1, datedeb: deb, datefin: fin} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://144.91.121.43/lis/allretardsAggr.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getAllAbsencesAggr(deb, fin): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1, datedeb: deb, datefin: fin} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://144.91.121.43/lis/allabsencesAggr.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }



  getAllRunningDemands(): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://144.91.121.43/dieulsima/public/index.php/api/allrunningcourses', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getAllFinishedDemands(): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: 1} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://144.91.121.43/dieulsima/public/index.php/api/allfinishedcourses', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }



  getCurrencies(samacaabiyoonwii): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger(this.baseUrl+'getAllCurrencies.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  saveChangeOperation(samacaabiyoonwii, choosenCode, choosenNom, 
	    				isIconed, choosenIcon, montantCurrency, operation, 
	    				montantCFA, infosclient, changeRate): Promise<any>{  

    return new Promise( (resolve, reject) => {

		let postData = {samacaabiyoonwii: samacaabiyoonwii, choosenCode:choosenCode, 
							choosenNom:choosenNom, isIconed:isIconed, choosenIcon:choosenIcon, 
								montantCurrency:montantCurrency, operation:operation, 
								montantCFA:montantCFA, infosclient:infosclient, changeRate:changeRate } ;  

	    let backData  : ReponseWebService ;

	    console.log(postData) ;

	    this.remotelogger(this.baseUrl+'saveChangeOperation.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }

	getPendingVersement(samacaabiyoonwii: string): Promise<any>{  
		return new Promise( (resolve, reject) => {
			let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger(this.baseUrl+'getPendingVersement.php', postData ).subscribe(
		        retourPost => {
		          	backData = retourPost ;
		        },
		        response => {

		            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
				    console.log("THere we are 73") ;
		            resolve(backData);
		        }
		   	) ;
		});
	}


	validerVersement(samacaabiyoonwii: string, opjdhghfd): Promise<any>{  
		return new Promise( (resolve, reject) => {
			let postData = {samacaabiyoonwii: samacaabiyoonwii, opjdhghfd: opjdhghfd } ;  
		    let backData  : ReponseWebService ;

		    console.log(postData) ;

		    this.remotelogger(this.baseUrl+'validerVersement.php', postData ).subscribe(
		        retourPost => {
		          	backData = retourPost ;
		        },
		        response => {

		            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
				    console.log("THere we are 73") ;
		            resolve(backData);
		        }
		   	) ;
		});
	}


  getAllCurrenciesRates(samacaabiyoonwii): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger(this.baseUrl+'getAllCurrenciesRates.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }



  getAllCurrenciesSoldes(samacaabiyoonwii): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger(this.baseUrl+'getAllCurrenciesSoldes.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }



  getCaisseXOF(samacaabiyoonwii): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger(this.baseUrl+'getCaisseXOF.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }



  getAllTransactions(samacaabiyoonwii, debut, fin): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii: samacaabiyoonwii, dateBiniouTambalei:debut, dateBiniouDieekhal:fin } ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger(this.baseUrl+'getAllTransactions.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getAllPartners(samacaabiyoonwii): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger(this.baseUrl+'allUsers.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


	getAllCompanies(samacaabiyoonwii: string): Promise<any>{  
		return new Promise( (resolve, reject) => {
			let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger(this.baseUrl+'allPoints.php', postData ).subscribe(
		        retourPost => {
		          	backData = retourPost ;
		        },
		        response => {

		            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
				    console.log("THere we are 73") ;
		            resolve(backData);
		        }
		   	) ;
		});
	}



	getAllActifsPoints(samacaabiyoonwii: string): Promise<any>{  
		return new Promise( (resolve, reject) => {
			let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger(this.baseUrl+'allActifsPoints.php', postData ).subscribe(
		        retourPost => {
		          	backData = retourPost ;
		        },
		        response => {

		            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
				    console.log("THere we are 73") ;
		            resolve(backData);
		        }
		   	) ;
		});
	}


	getAllInactifsPoints(samacaabiyoonwii: string): Promise<any>{  
		return new Promise( (resolve, reject) => {
			let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger(this.baseUrl+'allInactifsPoints.php', postData ).subscribe(
		        retourPost => {
		          	backData = retourPost ;
		        },
		        response => {

		            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
				    console.log("THere we are 73") ;
		            resolve(backData);
		        }
		   	) ;
		});
	}


	getAllActifsUsers(samacaabiyoonwii: string): Promise<any>{  
		return new Promise( (resolve, reject) => {
			let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger(this.baseUrl+'allActifsUsers.php', postData ).subscribe(
		        retourPost => {
		          	backData = retourPost ;
		        },
		        response => {

		            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
				    console.log("THere we are 73") ;
		            resolve(backData);
		        }
		   	) ;
		});
	}


	getAllInactifsUsers(samacaabiyoonwii: string): Promise<any>{  
		return new Promise( (resolve, reject) => {
			let postData = {samacaabiyoonwii: samacaabiyoonwii} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger(this.baseUrl+'allInactifsUsers.php', postData ).subscribe(
		        retourPost => {
		          	backData = retourPost ;
		        },
		        response => {

		            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
				    console.log("THere we are 73") ;
		            resolve(backData);
		        }
		   	) ;
		});
	}



  getPeriodeSituation(samacaabiyoonwii: string, datedebut: string, datefin: string ): Promise<any>{  
    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii: samacaabiyoonwii, dateBiniouTambalei:datedebut, dateBiniouDieekhal:datefin} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger(this.baseUrl+'situationPeriode.php', postData ).subscribe(
	        retourPost => {
	          	backData = retourPost ;
	        },
	        response => {

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }

/*
	download(fileName: string) {
	    const options = new RequestOptions({
	        responseType: ResponseContentType.Blob
	    });

	    return this.http.get(this.baseUrl+'rprts', options);
	}
*/

  getAllBulletins(datedeb, datefin): Promise<any>{  
  	
    return new Promise( (resolve, reject) => {

		let postData = {idUser: 1, datedeb: datedeb, datefin: datefin} ;  

	    let backData  : ReponseWebService ;

	    console.log(postData) ;

	    this.remotelogger('http://144.91.121.43/lis/allpaynotif.php', postData ).subscribe(
	        retourPost => {          
			    console.log("THere we are 62") ;
	          	backData = retourPost ;
	          	console.log("*****") ;
	          	console.log(backData) ;
	        },
	        response => {
			    console.log("THere we are 67") ;
			    console.log(response) ;

	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
			    console.log("THere we are 73") ;
	            resolve(backData);
	        }
	   	) ;
	});
  }


  updateConfig(idUser, config, nomassurance): Promise<ReponseWebService>{   
    return new Promise( (resolve, reject) => {

		let postData = {idUser: idUser, config:config, nomassurance: nomassurance } ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://localhost/Galia/updateconfig.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }

  updateNomAssurance(idUser, nomassurance, newname, newIntitule): Promise<ReponseWebService>{
    return new Promise( (resolve, reject) => {

		let postData = {idUser: idUser, nomassurance:nomassurance, newname:newname, intitule:newIntitule } ;  
	    let backData  : ReponseWebService ;

	    console.log(postData) ;

	    this.remotelogger('http://localhost/Galia/updatenomass.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }


  updatePolicy(idUser, nomassurance, policyValue): Promise<ReponseWebService>{
    return new Promise( (resolve, reject) => {

		let postData = {idUser: idUser, nomassurance:nomassurance, policy:policyValue } ;  
	    let backData  : ReponseWebService ;

	    console.log(postData) ;

	    this.remotelogger('http://localhost/Galia/updatePolicy.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }


  deleteAssurance(idUser, nomassurance): Promise<ReponseWebService>{
    return new Promise( (resolve, reject) => {

		let postData = {idUser: idUser, nomassurance:nomassurance } ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://localhost/Galia/deleteconfig.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }


  addNewParamPredef(idUser, nomparam, nomFrmt, paramvalues): Promise<ReponseWebService>{   
    return new Promise( (resolve, reject) => {

		let postData = {idUser: idUser, paramvalues: JSON.stringify(paramvalues), nomparam:nomparam, nomFrmt: nomFrmt} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://localhost/Galia/addparampredf.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }

  getAllParamsPredef(idUser): Promise<ReponseWebService>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: idUser} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://localhost/Galia/getPredefparams.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;
	          	console.log(backData) ;
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }

  getAllParamRecurrent(idUser, nomFrmtRec): Promise<ReponseWebService>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: idUser, nomFrmtRec: nomFrmtRec} ;  
	    let backData  : ReponseWebService ;

	    this.remotelogger('http://localhost/Galia/getRecurrentparams.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;
	          	console.log(backData) ;
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }


  createCompanyAndAdmin(idUser, companyName, regionPoint, adressepoint): Promise<ReponseWebService>{

    return new Promise( (resolve, reject) => {
		let postData = {idUser: idUser, companyName:companyName, regionPoint:regionPoint, adressepoint:adressepoint} ;  
	    let backData  : ReponseWebService ;
	    
	    console.log(postData);

	    this.remotelogger(this.baseUrl+'addPoint.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getDevis(idUser, nomassurance, nomcateg, allgarantie, matricule, duree, params, paramvalues): Promise<ReponseWebService>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: idUser, nomAssurance:nomassurance, nomCateg:nomcateg, allgarantie:allgarantie, matricule:matricule, duree:duree, params:params, paramsValues:paramvalues} ;  
	    let backData  : ReponseWebService ;
	    this.remotelogger('http://localhost/Galia/getDevis.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;
	          	console.log(backData) ;
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	   	
	});
  }

  souscrire(idUser, nomAssurance, nomCateg, alldevis, matricule, duree, infoscar, paramsValues, total, nomclient, prenomclient, telephoneclient): Promise<ReponseWebService>{  
    return new Promise( (resolve, reject) => {
		let postData = {idUser: idUser, nomAssurance:nomAssurance, nomCateg:nomCateg, alldevis:alldevis, matricule:matricule, duree:duree, infoscar:JSON.stringify(infoscar), paramsValues:paramsValues, total: total, nomclient:nomclient, prenomclient:prenomclient, telephoneclient:telephoneclient} ;  
	    let backData  : ReponseWebService ;

	    console.log(JSON.stringify(postData)) ;

	    this.remotelogger('http://localhost/Galia/souscrire.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;
	          	console.log(backData) ;
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	   	
	});
  }


  getGlobalProductionByDateInterval(idUser, datedeb, datefin): Promise<ReponseWebService>{

		return new Promise( (resolve, reject) => {
			let postData = {idUser: idUser, datedeb: datedeb, datefin: datefin} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger('http://localhost/Galia/getproductionbydateintervalle.php', postData ).subscribe(
		        retourPost => {          
		          	backData = retourPost ;
		        },
		        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
		            resolve(backData);
		        }
		   	) ;
		});
  }


  getDetailProdLine(idprod): Promise<ReponseWebService>{
		return new Promise( (resolve, reject) => {
			let postData = {idprod: idprod} ;  
		    let backData  : ReponseWebService ;

		    this.remotelogger('http://localhost/Galia/getDetailProdLine.php', postData ).subscribe(
		        retourPost => {          
		          	backData = retourPost ;
		        },
		        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
		        },
		        () => {
		            resolve(backData);
		        }
		   	) ;
		});
  }


  createUser(samacaabiyoonwii, idUser, prenomUser, nomUser, telephoneUser, idpoint, nompoint, regionPoint, adressepoint): Promise<ReponseWebService>{

    return new Promise( (resolve, reject) => {
		let postData = {samacaabiyoonwii:samacaabiyoonwii, idUser: idUser, prenom:prenomUser, nom:nomUser, idpoint:idpoint, telephone:telephoneUser, regionPoint:regionPoint, adressepoint:adressepoint, nompoint:nompoint } ;  
	    let backData  : ReponseWebService ;
	    
	    console.log(postData) ;
	    
	    this.remotelogger(this.baseUrl+'addUser.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;        
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }



  updateplanning(idUser, prenomUser, nomUser, telephoneUser, companyName, activeDays, pointHours, descenteHours): Promise<ReponseWebService>{

    return new Promise( (resolve, reject) => {
		let postData = {idUser: idUser, prenom:prenomUser, nom:nomUser, companyName:companyName, telephone:telephoneUser, activeDays:activeDays, pointHours:pointHours, descenteHours:descenteHours } ;  
	    let backData  : ReponseWebService ;
	    
	    console.log(postData) ;
	    
	    this.remotelogger('http://144.91.121.43/lis/updateplanning.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }


  getUserPlanning(idUser,): Promise<ReponseWebService>{

    return new Promise( (resolve, reject) => {
		let postData = {idUser: idUser } ;  
	    let backData  : ReponseWebService ;
	    
	    console.log(postData) ;
	    
	    this.remotelogger('http://144.91.121.43/lis/getuserplanning.php', postData ).subscribe(
	        retourPost => {          
	          	backData = retourPost ;          
	        },
	        response => {
	            backData = {statutreq: 0, message : "Erreur! Vérifiez votre connexion Internet", data : '', status : true, chauffeurs : []};
	        },
	        () => {
	            resolve(backData);
	        }
	   	) ;
	});
  }



  remotelogger(url: string, sentData: any) {
    return this.http.post<ReponseWebService>(url, sentData, httpOptions);
  }


}
