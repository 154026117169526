import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';


@Component({
  selector: 'app-userplanning',
  templateUrl: './userplanning.component.html',
  styleUrls: ['./userplanning.component.css']
})
export class UserplanningComponent implements OnInit {

	selectedTime : any ;
	pickerA : any ;

	hpointe : any ;
	hpointe0 : any ;
	hpointe1 : any ;
	hpointe2 : any ;
	hpointe3 : any ;
	hpointe4 : any ;
	hpointe5 : any ;
	hpointe6 : any ;

	hdescente : any ;

	hpointe10 : any ;
	hpointe11 : any ;
	hpointe12 : any ;
	hpointe13 : any ;
	hpointe14 : any ;
	hpointe15 : any ;
	hpointe16 : any ;

	retourClientWS : any ;

	doneCreating = 0 ;

	time : any ;
	timedesc : any ;

	userinfos : any ;

	selections = [false, false, false, false, false, false, false] ;

	selectionsColor = ['warn', 'warn', 'warn', 'warn', 'warn', 'warn', 'warn'] ;

	hpointes = [undefined, undefined, undefined, undefined, undefined, undefined, undefined] ;

	hdescentes = [undefined, undefined, undefined, undefined, undefined, undefined, undefined] ;
  
  constructor(private configServer: ServicepoolService, public dialogRef: MatDialogRef<UserplanningComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) {
  	this.userinfos = data.opt ;
  	this.getUserPlanning() ;
  	console.log(this.userinfos) ;
  }

  ngOnInit(): void {
  }

  select(dayInd){
  	this.selections[dayInd] = !this.selections[dayInd] ; 

  	if(this.selections[dayInd]==true)
  		this.selectionsColor[dayInd] = "primary" ;
  	else
  		this.selectionsColor[dayInd] = "warn" ;
  }

  timeChangeHandler($event, index){

  	var time : any ;
  	var timedesc : any ;

  	if(index==0){
		time = this.hpointe0.toLocaleTimeString();
  	}
  	if(index==1){
		time = this.hpointe1.toLocaleTimeString();
  	}
  	if(index==2){
		time = this.hpointe2.toLocaleTimeString();
  	}
  	if(index==3){
		time = this.hpointe3.toLocaleTimeString();
  	}
  	if(index==4){
		time = this.hpointe4.toLocaleTimeString();
  	}
  	if(index==5){
		time = this.hpointe5.toLocaleTimeString();
  	}
  	if(index==6){
		time = this.hpointe6.toLocaleTimeString();
  	}

  	if(index==10){
		timedesc = this.hpointe10.toLocaleTimeString();
  	}
  	if(index==11){
		timedesc = this.hpointe11.toLocaleTimeString();
  	}
  	if(index==12){
		timedesc = this.hpointe12.toLocaleTimeString();
  	}
  	if(index==13){
		timedesc = this.hpointe13.toLocaleTimeString();
  	}
  	if(index==14){
		timedesc = this.hpointe14.toLocaleTimeString();
  	}
  	if(index==15){
		timedesc = this.hpointe15.toLocaleTimeString();
  	}
  	if(index==16){
		timedesc = this.hpointe16.toLocaleTimeString();
  	}


  	if(index<10)
		this.hpointes[index] = time ;
	else
		this.hdescentes[index-10] = timedesc ;

  }


  setPlanTime(time, index){
  	if(index==0){
		this.hpointe0 = time.toLocaleDateString() ;
  	}
  	if(index==1){
		this.hpointe1 = time ;
  	}
  	if(index==2){
		this.hpointe2 = time ;
  	}
  	if(index==3){
		this.hpointe3 = time ;
  	}
  	if(index==4){
		this.hpointe4 = time ;
  	}
  	if(index==5){
		this.hpointe5 = time ;
  	}
  	if(index==6){
		this.hpointe6 = time ;
  	}

	this.hpointes[index] = time ;

  }

  getUserPlanning(){

	this.configServer.getUserPlanning(this.userinfos.id).then( (retourbi) => {
		this.retourClientWS = retourbi ;
		var statutreq = this.retourClientWS.statutreq ;
		console.log(this.retourClientWS) ;
		if(statutreq==1){ 
		  console.log("Done") ;
		  	for (var i = 0; i < this.selections.length; i++) {
		  		if (this.retourClientWS.data.activedays.includes(i.toString()) ){
		  			this.select(i) ;
		  			this.selections[i] = true ;

		  			this.hpointes[i] = JSON.parse(this.retourClientWS.data.pointehours)[i] ;

		  			//this.setPlanTime(JSON.parse(this.retourClientWS.data.pointehours)[i], i) ;
		  		}
		  	}

		}
		else
		  console.log("Failed") ;
	}) ;          


  }


  annuler(){
  	this.dialogRef.close() ;
  }


  register(){
  	var activeDays = "" ;
  	var pointHours = [] ;
  	var descenteHours = [] ;

  	for (var i = 0; i < this.selections.length; i++) {
  		if( this.selections[i]==true ){
  			if(activeDays!=""){
		  		activeDays = activeDays +"___"+ i.toString() ;
	  		}else{
		  		activeDays = activeDays + i.toString() ;
	  		}
  		}
		pointHours.push( this.hpointes[i] ) ;
		descenteHours.push( this.hdescentes[i] ) ;
  	}

  	console.log( descenteHours ) ;

	this.configServer.updateplanning(this.userinfos.id, this.userinfos.prenom, this.userinfos.nom, this.userinfos.telephone, this.userinfos.entreprise, activeDays, JSON.stringify(pointHours), JSON.stringify(descenteHours) ).then( (retourbi) => {
		this.retourClientWS = retourbi ;
		var statutreq = this.retourClientWS.statutreq ;
		console.log(this.retourClientWS) ;
		if(statutreq==1){
		  console.log("Done") ;
		  this.doneCreating = 1 ;
		}
		else
		  console.log("Failed") ;
	}) ;          
  	
  }


  invalidInputHandler(){
  	console.log("Saisie Invalide !!!") ;
  }

}
