import { Component, OnInit, Inject, Input, Output, ViewChild, AfterViewInit, ElementRef} from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { DetailcommandeComponent } from '../detailcommande/detailcommande.component';
import {Observable} from 'rxjs';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';


@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.css']
})
export class ChangeComponent implements OnInit {

   
   currencies : any ; /* [ 
					{id: 1, code: "USD" , nom: "Dollar Americain", hasicon: 1, icon: "attach_money"}, 
					{id: 2, code: "Eur" , nom: "Euro", hasicon: 1, icon: "euro"},
					{id: 3, code: "GBP" , nom: "Livre Britanique", hasicon: 1, icon: "currency_pound"},
					{id: 4, code: "CNY" , nom: "YUAN Chinois", hasicon: 1, icon: "currency_yuan"},
					{id: 4, code: "RUB" , nom: "Rouble Russe", hasicon: 1, icon: "currency_ruble"},
					{id: 5, code: "JPY" , nom: "Yen Japonais", hasicon: 1, icon: "currency_yen"},
					{id: 6, code: "INR" , nom: "Roupie Indienne", hasicon: 1, icon: "currency_rupee"},
				];
*/

	allTaux : any ; /*[ 
				{code: "USD" , nom: "Dollar Americain", hasicon: 1, icon: "attach_money", achat: 620, vente: 670 }, 
				{code: "Eur" , nom: "Euro", "hasicon": 1, icon: "euro", achat: 650, vente: 680 },
				{code: "GBP" , nom: "Livre Britanique", hasicon: 1, icon: "currency_pound", achat: 730, vente: 780 },
				{code: "CNY" , nom: "YUAN Chinois", hasicon: 1, icon: "currency_yuan", achat: 90, vente: 130 },
				{code: "RUB" , nom: "Rouble Russe", hasicon: 1, icon: "currency_ruble", achat: 10, vente: 15 },
				{code: "JPY" , nom: "Yen Japonais", hasicon: 1, icon: "currency_yen", achat: 5, vente: 15 },
				{code: "INR" , nom: "Roupie Indienne", hasicon: 1, icon: "currency_rupee", achat: 650, vente: 680 },
			] ;*/

 
	isIconed = 0 ;    
	choosenIcon = "" ;    
	choosenCode = "" ;    
	choosenNom = "" ;    
	choosenId = 0 ;    


	prenom : any ;
	nom : any ;
	telephone : any ;
	cni : any ;

	@Input() loadedLabel : any;

	samacaabiyoonwii = "" ;


	choosenCurrSalePrice = 0 ;
	choosenCurrBuyPrice = 0 ;

	volet = 'transact' ; 

	voletTampon : any ;

	successOp = false ;
	failureOp = false ;

	operation = "" ;

	retourClientWS  : ReponseWebService ;

	montant : any ;

	isIconedPrnt = 0 ;    
	choosenIconPrnt = "" ;    
	choosenCodePrnt = "" ;    
	choosenNomPrnt = "" ;    
	choosenIdPrnt = 0 ;    
	operationPrnt = "" ;
	montantPrnt : any ;

	changeRatePrnt : any ;

	nomagent : any ;
	prenomagent : any ;
	nompoint : any ;
	adressepoint : any ;
	region : any ;

	constructor(private elementRef: ElementRef, public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {

	}

	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
	    this.getCurrencies() ;
	    this.getAllCurrenciesRates() ;


        this.nomagent = sessionStorage.getItem("nomagent") ;
        this.prenomagent = sessionStorage.getItem("prenomagent") ;
        this.nompoint = sessionStorage.getItem("nompoint") ;
        this.adressepoint = sessionStorage.getItem("adressepoint") ;
        this.region = sessionStorage.getItem("region") ;

	}


	onCurrencyChange(event){
		let choosenIndex = event.value ;		

		this.isIconed = this.currencies[choosenIndex].hasicon ;    
		this.choosenIcon = this.currencies[choosenIndex].icon ;    
		this.choosenCode = this.currencies[choosenIndex].code ;    
		this.choosenNom = this.currencies[choosenIndex].nom ;    
		this.choosenId = this.currencies[choosenIndex].id ; 

		this.getCurrencySaleAndBuy() ;   
	}


    getCurrencies(){

	    this.configServer.getCurrencies(this.samacaabiyoonwii).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        this.currencies = this.retourClientWS.data ; 
	      }
	      else
	        console.log("Failed") ;
	    }) ;    
	}


    getAllCurrenciesRates(){

	    this.configServer.getAllCurrenciesRates(this.samacaabiyoonwii).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        this.allTaux = this.retourClientWS.data ; 
	      }
	      else
	        console.log("Failed") ;
	    }) ;
	}


	getCurrencySaleAndBuy(){
		let theCndtns = this.allTaux.find(x => (x.code == this.choosenCode && x.nom == this.choosenNom ) ) ;

		this.choosenCurrSalePrice = theCndtns.vente ;
		this.choosenCurrBuyPrice = theCndtns.achat ;
	}



	printReceipt(): void {

		let printContents = document.getElementById('receipt-content').innerHTML;
	    let popupWin = window.open('', '_blank', 'width=700,height=800');
	    popupWin.document.open();
	    popupWin.document.write(`
	      <html>
	        <head>
	          <title>Reçu Transaction</title>
	          <style>
	            /* Styles CSS */
	          </style>
	        </head>
	        <body onload="window.print();window.close()">${printContents}</body>
	      </html>`
	    );
	    popupWin.document.close();
	}

	saveOperation(){

		this.voletTampon =this.volet ;
		this.volet = "loader" ;

		let changeRate = 0 ;

		if(this.operation=='vente'){
			changeRate = this.choosenCurrSalePrice ;
		}
		if(this.operation=='achat'){
			changeRate = this.choosenCurrBuyPrice ;
		}

		let infosclientObjet = { "prenom": this.prenom, "nom": this.nom, "telephone": this.telephone, "cni" : this.cni } ;

		let infosclient = JSON.stringify(infosclientObjet) ;

	    this.configServer.saveChangeOperation(this.samacaabiyoonwii, this.choosenCode, this.choosenNom, 
	    				this.isIconed, this.choosenIcon, this.montant, this.operation, 
	    				changeRate*this.montant, infosclient, changeRate).then( (retourbi) => {

	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        this.allTaux = this.retourClientWS.data ; 

		    this.volet = this.voletTampon;
			this.successOp = true ;
			//this.failureOp = false ;

			this.isIconedPrnt = this.isIconed ;    
			this.choosenIconPrnt = this.choosenIcon;    
			this.choosenCode = this.choosenCodePrnt = this.choosenCode;    
			this.choosenNomPrnt = this.choosenNom ;    
			this.choosenIdPrnt = this.choosenId ;    
			this.operationPrnt = this.operation ;
			this.montantPrnt = this.montant ;
			this.changeRatePrnt = changeRate ;

			this.isIconed = 0 ;    
			this.choosenIcon = "" ;    
			this.choosenCode = "" ;    
			this.choosenNom = "" ;    
			this.choosenId = 0 ;    
			this.operation = "" ;
			this.montant = undefined ;
	      }
	      else
	        console.log("Failed") ;
	    }) ;

/*

		if(this.operation=='vente'){
			let newSoldeVente = Number(sessionStorage.getItem("totalVente")) + this.choosenCurrSalePrice*this.montant ;
			sessionStorage.setItem("totalVente", newSoldeVente.toString()) ;

			let newOp = {code: this.choosenCode , nom: this.choosenNom, 
						hasicon: this.isIconed, icon: this.choosenIcon, 
						montant: this.montant, operation: this.operation , 
						coutprix: this.choosenCurrSalePrice*this.montant } ;
			let opsString = sessionStorage.getItem("allOps") ;
			if(opsString!=undefined){		
				console.log(opsString) ;	
				let ops = JSON.parse(opsString) ;
				ops.push(newOp) ;
				sessionStorage.setItem("allOps", JSON.stringify(ops) ) ;
			}
		}
		if(this.operation=='achat'){
			let newSoldeVente = Number(sessionStorage.getItem("totalAchat")) + this.choosenCurrBuyPrice*this.montant ;
			sessionStorage.setItem("totalAchat", newSoldeVente.toString()) ;

			let newOp = {code: this.choosenCode , nom: this.choosenNom, 
						hasicon: this.isIconed, icon: this.choosenIcon, 
						montant: this.montant, operation: this.operation , 
						coutprix: this.choosenCurrBuyPrice*this.montant } ;

			let opsString = sessionStorage.getItem("allOps") ;
			if(opsString!=undefined){		
				console.log(opsString) ;	
				let ops = JSON.parse(opsString) ;
				ops.push(newOp) ;
				sessionStorage.setItem("allOps", JSON.stringify(ops) ) ;
			}
		}		 

*/

/*		setTimeout(()=>{
		    this.volet = this.voletTampon;
			this.successOp = true ;
			//this.failureOp = false ;

			this.isIconed = 0 ;    
			this.choosenIcon = "" ;    
			this.choosenCode = "" ;    
			this.choosenNom = "" ;    
			this.choosenId = 0 ;    
			this.operation = "" ;
			this.montant = undefined ;

		}, 3000);
*/

	}



}
