import { Component, OnInit, Inject } from '@angular/core';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';



@Component({
  selector: 'app-detailcommande',
  templateUrl: './detailcommande.component.html',
  styleUrls: ['./detailcommande.component.css']
})
export class DetailcommandeComponent implements OnInit {

	detailsCommande = [] ;

	retourClientWS  : ReponseWebService ;

	productionId : any ;

	theProduction : any ;
	infosProd : any ;
	autresinfos : any ;
	garanties : any ;
	montantdevis : any ;
	prenom : any ;
	nom : any ;
	tel : any ;

	partners = [] ;
	partner : any ;
	current = 0 ;

	choosedChauffeurId : any ;


	allChauffeurs = [] ;


	constructor( private configServer: ServicepoolService, public dialogRef: MatDialogRef<DetailcommandeComponent>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any ) {
    	this.theProduction = data.elt ;
    	this.tel = JSON.parse(this.theProduction.infoClient).telephone;
		console.log("The Production ID IS") ;
		console.log(this.productionId) ;
	}

	ngOnInit(): void {
	    this.getAllChauffeurs();
	}

	getDetailProdLine(theIncomingProduction){
    	this.theProduction = theIncomingProduction ;
	}

	getAllChauffeurs(){

	}


	choosedInSelect(chauffeur){
		 this.choosedChauffeurId = chauffeur.id ;
	}


	assignerCourse(){

		this.configServer.assignerCourse(this.theProduction.id, this.choosedChauffeurId).then( (retourbi) => {

		  this.retourClientWS = retourbi ;
		  var statutrec = this.retourClientWS.status;

		  if(statutrec==true){
		    console.log("Done") ;
		    this.dialogRef.close() ;
		  }
		  else{
		    console.log("Failed") ;
		  }
		}) ;    		
	}

}
