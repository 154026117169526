import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { HttpClient, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';

import { ReponseWebService } from '../datastruct/Meloyi.module';

import { ServicepoolService } from '../services/servicepool.service';



export interface ConfirmDialogData {
  infoscar : any; 
  alldevis: any;
  total: any;
  nomAssurance: any ;
  nomCateg: any ;
  matricule: any ;
  duree: any ;
  paramsValues: any ;
}


@Component({
  selector: 'app-confirmsouscription',
  templateUrl: './confirmsouscription.component.html',
  styleUrls: ['./confirmsouscription.component.css']
})
export class ConfirmsouscriptionComponent implements OnInit {

	infoscar : any ;
	alldevis : any ;
	total : any ;
  nomAssurance: any ;
  nomCateg: any ;
  matricule: any ;
  duree: any ;
  paramsValues : any;

  retourClientWS  : ReponseWebService ;


	nomclient: any ;
	prenomclient: any ;
	telephoneclient: any ;

	constructor(private dialogRef: MatDialogRef<ConfirmsouscriptionComponent>,  @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,  private configServer: ServicepoolService) { 
	    this.infoscar = data.infoscar ;
	    this.alldevis = data.alldevis ;
	    this.nomAssurance = data.nomAssurance ;
	    this.nomCateg = data.nomCateg ;
	    this.matricule = data.matricule ;
	    this.duree = data.duree ;
	    this.total = data.total ;
	    this.paramsValues = data.paramsValues ;
	}

	ngOnInit(): void {
	}


	souscrire(){
	    this.configServer.souscrire(1, this.nomAssurance, this.nomCateg, this.alldevis, this.matricule, this.duree, this.infoscar, this.paramsValues, this.total, this.nomclient, this.prenomclient, this.telephoneclient).then( (retourbi) => {
	        this.retourClientWS = retourbi ;
	        var statutrec = this.retourClientWS.statutreq ;
	        console.log(this.retourClientWS) ;

	        if(statutrec==1){
		      this.dialogRef.close("Done");		
	          console.log("Done") ;
	        }
	        else
	          console.log("Failed") ;
	    }) ;    

	}

}
