import { Component, OnInit, Inject, Input, Output, ViewChild, AfterViewInit} from '@angular/core';

import { HttpClient, HttpHeaders, HttpResponse, HttpEventType } from '@angular/common/http';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

import { DetailcommandeComponent } from '../detailcommande/detailcommande.component';
import {Observable} from 'rxjs';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ServicepoolService } from '../services/servicepool.service';
import { ReponseWebService } from '../datastruct/Meloyi.module';



@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {



  commands = [] ;

  allTheBanks : any ;

  allServiceSituation : any ;

  datedeb : any ;
  datefin : any ;

  displayedColumnsPoint: string[] = ['nompoint', 'region', 'adresse',  'created_at', 'details'];
  displayedColumns: string[] = ['prenom', 'nom', 'telephone', 'point', 'adresse', 'region', 'created_at', 'details'];

  dataSource : any ;

  dataSourceBanks : any ;

  dataSourceActifPoints : any ;
  dataSourceInactifPoints : any ;

  dataSourceServiceSituation : any ;
  dataSourceInactifUsers : any ;

  versementEnCours = 0 ;

  choosenGroup = "Banques" ;



  montantProgrammeVersement = 1000 ;

  currencyProgrammeVersement = 1000 ;

  initiateur : any ;

  opjdhghfd : any ;


  nbreActifs = 0 ;
  nbreInactifs = 0 ;

  nbreActifUsers = 0 ;
  nbreInactifUsers = 0;
  
  userchoice = 1 ;

  samacaabiyoonwii = "" ;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  retourClientWS  : ReponseWebService ;

  constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
      this._locale = 'fr';
      this._adapter.setLocale(this._locale);
      this.datedeb = new Date(); 
      this.datefin = new Date();     
  }
  

  ngOnInit(): void {

    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
    //this.getPendingVersement();
  }

  
    getProductionByDateInterval(){

      var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
      var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

      this.configServer.getGlobalProductionByDateInterval(1, debut, fin).then( (retourbi) => {
        this.retourClientWS = retourbi ;
        var statutrec = this.retourClientWS.statutreq ;

        if(statutrec==1){
          console.log("Done") ;
          this.allTheBanks = this.retourClientWS.data ; 
          this.dataSource =  new MatTableDataSource(this.allTheBanks) ;
          this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        }
        else
          console.log("Failed") ;
      }) ;    
  }

  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSourceBanks.filter = filterValue.trim().toLowerCase();
  }


  annuler(){
    this.versementEnCours=0;
    this.montantProgrammeVersement = 0 ;
    this.initiateur = undefined ;
  }


  loadInfos(inside){

    this.userchoice = inside ;

  }




  getPendingVersement(){

    this.configServer.getPendingVersement(this.samacaabiyoonwii).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

      if(statutreq==1){
        console.log("Done") ;
        this.montantProgrammeVersement = this.retourClientWS.data.montant ; 
        this.currencyProgrammeVersement = this.retourClientWS.data.nomcurrency ;
        this.initiateur = JSON.parse(this.retourClientWS.data.infosinitiateur) ;

        this.opjdhghfd = this.retourClientWS.data.id ;
        this.versementEnCours=1;
        console.log(this.montantProgrammeVersement) ;
      }
      else{

          this.versementEnCours=-1;
          console.log("Failed") ;
      }
    }) ;    
  }


  validerVersement(){

    this.configServer.validerVersement(this.samacaabiyoonwii, this.opjdhghfd).then( (retourbi) => {
      this.retourClientWS = retourbi ;
      var statutreq = this.retourClientWS.statutreq;

       console.log(this.retourClientWS) ;

      if(statutreq==1){
        console.log("Done") ;
        this.versementEnCours = -2 ;
      }
      else{

          this.versementEnCours=-3;
          console.log("Failed") ;
      }
    }) ;    
  }


  getMontantFormatee(montant){
    let resultd = Number(montant).toLocaleString('fr-FR');
    return resultd ;
  }


}


 