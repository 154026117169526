import { Component, OnInit, Inject } from '@angular/core';

import {MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ConfirmDialogData {
  title : string; 
  message: string;
}


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  title : string ;
  message : string ;

  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData){ 
    this.title = data.title ;
    this.message = data.message ;
  }

  ngOnInit(){
  }

  confirmClick(returnedValue): void {
     this.dialogRef.close("dlt");
  }


  cancelClick(){
    this.dialogRef.close("ccl");    
  }


}
