import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { formatDate } from "@angular/common";

import {MatTableDataSource, MatTable} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';


import { ServicepoolService } from '../services/servicepool.service';

import { ReponseWebService } from '../datastruct/Meloyi.module';


export interface Transaction {
  code: string;
  nom: string;
  hasicon: number;
  icon: string;
  montant: string;
  operation: string;
  coutprix: number;
}

 

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css']
})
export class HistoriqueComponent implements OnInit {

	allTransactions  : any ;

	displayedColumns: string[] = ['Date', 'Operation', 'Monnaie', 'Taux Change', 'Montant Devise', 'Coutprix'];
	dataSource  : any ;

	intervalId : any ;

	datedeb : any ;
	datefin : any ;


	samacaabiyoonwii : any ;
	retourClientWS  : ReponseWebService ;

	@ViewChild(MatTable) table: MatTable<Transaction>;

	constructor(public dialog: MatDialog,  private configServer: ServicepoolService, private _adapter: DateAdapter<any>, @Inject(MAT_DATE_LOCALE) private _locale: string) {
	    this._locale = 'fr';
	    this._adapter.setLocale(this._locale);
	    this.datedeb = new Date(); 
	    this.datefin = new Date(); 		
	}

	ngOnInit(): void {
	    this.samacaabiyoonwii = sessionStorage.getItem("samacaabiyoonwii") ;
		this.updateHisto() ;
	}



	updateHisto(){

	    var debut = formatDate(this.datedeb, "yyyy-MM-dd", 'en-US');
	    var fin = formatDate(this.datefin, "yyyy-MM-dd", 'en-US');

	    this.configServer.getAllTransactions(this.samacaabiyoonwii, debut, fin).then( (retourbi) => {
	      this.retourClientWS = retourbi ;
	      var statutrec = this.retourClientWS.statutreq ;

	      if(statutrec==1){
	        this.allTransactions = this.retourClientWS.data ; 

	        console.log(this.allTransactions) ;
		    this.dataSource = new MatTableDataSource(this.allTransactions);
	      }
	      else
	        console.log("Failed") ;
	    }) ;	    


	}


	getMontantFormatee(montant){
		let resultd = Number(montant).toLocaleString('fr-FR');
		return resultd ;
	}


	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}


	refreshTable(): void {
	}


}
