import { Component, OnInit } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';

import * as sha1 from 'js-sha1';


const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};


@Component({
  selector: 'app-exchanger',
  templateUrl: './exchanger.component.html',
  styleUrls: ['./exchanger.component.css']
})
export class ExchangerComponent implements OnInit {

	loader = false ;

  choosenTab : any;
	
  constructor(private http: HttpClient, private router: Router) {
    
  }

  ngOnInit(): void {
  }


  updateStateOf(cpnt){
  	console.log("Component "+cpnt) ;
    this.choosenTab = cpnt ;
  }

  guenneKo(){
    this.disconnect() ;
  }


  disconnect(){    

    let samacaabiyoonwiiThere = sessionStorage.getItem("samacaabiyoonwii") ;

    console.log(samacaabiyoonwiiThere);

    let logdata = {samacaabiyoonwii: samacaabiyoonwiiThere } ;  

    this.remoteDisconnect('https://change.smkfinances.com/change/disconnect.php', logdata ).subscribe(
        retourPost => {
          console.log(retourPost) ;
          sessionStorage.removeItem("accessl") ;
          sessionStorage.removeItem("samacaabiyoonwii") ;
          sessionStorage.clear();
          this.router.navigate(['/']) ;
    } ) ;              
  }

  remoteDisconnect(url: string, user: any) {
    return this.http.post(url, user, httpOptions);
  }



}
